<script setup lang="ts">
import Button from '~~/components/design-system/button.vue';

import { useSiteStore } from '~/stores/site.store';
import usePrepareMobileNumber from '~/composables/usePrepareMobileNumber';
import GenericError from '~/components/user-interface/generic-error.vue';
import { captcha } from 'assets/data/config';
import { globalKeys } from 'assets/data/global-keys';
import ToggleSwitch from 'primevue/toggleswitch';
import { radioButtonPassthrough } from '~/components/passthroughs/radiobutton';
import RadioButton from 'primevue/radiobutton';

const { $authService, $storageService } = useServices();
const { $t, $mobileNumberRegex } = useNuxtApp();
const site = useSiteStore();
const authStore = useAuthStore();
const errorCode = ref<string>('');
const errorRef = ref<string>('');

const form = ref();
const colorMode = useColorMode();

const prepareMobileNumber = usePrepareMobileNumber;

const countryCode = site.region;

const mobileNumber = computed(() =>
  prepareMobileNumber(form?.value?.data['username']),
);

const formLoading = ref<boolean>(false);

enum ECommunicationChannel {
  NONE,
  SMS,
  EMAIL,
}

const otpRadioOptions = [
  { value: 1, label: $t('sms') },
  { value: 2, label: $t('email') },
];

const enableEmailOption = computed(() =>
  site.getFeatureFlags.get('website.enableemail'),
);

const otpMethod = ref<ECommunicationChannel>(ECommunicationChannel.SMS);

const navigateToSuccess = () => {
  $storageService.setCookie({
    key: globalKeys.authKeys.passwordResetNumber,
    data: mobileNumber.value,
    expiry: 7,
  });
  authStore.setPasswordResetMobileNumber(mobileNumber.value);
  site.activateModal('passwordResetStepTwo');
};

const updateSettings = () => {
  formLoading.value = true;
  $storageService.setCookie({
    key: globalKeys.authKeys.passwordResetNumber,
    data: mobileNumber.value,
    expiry: 7,
  });
  authStore.setPasswordResetMobileNumber(mobileNumber.value);

  $authService
    .passwordResetInit({
      login: mobileNumber.value,
      countryCode,
      contactPreferences: otpMethod.value,
    })
    .then((data) => {
      if (data?.isSuccessful) {
        // redirect to otp confirm page
        navigateToSuccess();
      } else {
        errorCode.value = String(data?.error?.displayCode ?? 'general');
        errorRef.value = String(data?.error?.code ?? '');
      }
    })
    .finally(() => {
      formLoading.value = false;
    });
};
</script>

<template>
  <!-- Reset password wizard -->
  <div class="p-3">
    <div class="field col-12">
      <div class="text-xs">
        <p class="px-1 mb-0 text-black dark:text-white">
          {{ $t('enter.account.number') }}
        </p>
      </div>
      <Vueform
        ref="form"
        class="py-2"
        :float-placeholders="true"
        :endpoint="false"
      >
        <template #empty>
          <TextElement
            name="username"
            input-type="number"
            :attrs="{ inputmode: 'numeric' }"
            :placeholder="$t('placeholder.primaryMobileNumber')"
            :floating="$t('primaryMobileNumber')"
            :rules="['required', $mobileNumberRegex]"
            :messages="{ regex: $t('formErrors.primaryMobileNumber') }"
            :debounce="100"
          >
            <template #addon-before>
              <div
                class="bg-light-50 dark:bg-dark-800 flex flex-row justify-center items-center border-r border-solid std-border-color w-full h-full px-2"
              >
                {{ site.getDialingCode }}
              </div>
            </template>
          </TextElement>

          <div
            v-if="$enabled('website.enablerecaptcha')"
            class="mt-6 flex-center"
          >
            <CaptchaElement
              provider="recaptcha2"
              name="pw-reset-captcha"
              :default="captcha[site.getRegionCode]"
              :is-required="true"
              should-verify
              :options="{
                theme: colorMode.preference === 'dark' ? 'dark' : 'light',
                sitekey: captcha[site.getRegionCode],
              }"
            />
          </div>
        </template>
      </Vueform>
      <div class="" v-if="enableEmailOption">
        <p class="my-2 text-base-priority text-xs">
          {{ $t('select-preferred-communication-option') }}
        </p>

        <div class="grid grid-cols-2">
          <div
            v-for="(item, index) in otpRadioOptions"
            :key="item.value"
            class="flex items-center mr-3 text-dark-700 dark:text-white"
          >
            <RadioButton
              v-model="otpMethod"
              :inputId="String(item.value)"
              :name="item.label"
              :value="item.value"
              :pt="radioButtonPassthrough"
            />
            <label :for="item.label" class="ml-2 py-1 capitalize">
              {{ item.label }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Error messaging -->
    <div class="field-col-12 mb-3 mt-1 pt-0 flex justify-center">
      <GenericError
        v-if="errorCode"
        class="max-w-72 mx-auto"
        state="danger"
        :reference-code="errorRef"
      >
        {{ $t(`jpc-update-password-otp-error-` + errorCode) }}
      </GenericError>
    </div>
    <Button
      class="w-full justify-center"
      :disabled="!(form?.validated && !form?.invalid)"
      @click="updateSettings()"
    >
      {{ $t('send-reset-code') }}
    </Button>
  </div>
  <LazyUserInterfaceGenericLoader v-if="formLoading" container />
</template>
